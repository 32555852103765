const SheilaLogo = ({ className }) => {
    return (
        <svg
            viewBox="0 0 54 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                style={{ fill: 'currentColor' }}
                d="M27.6729 57.9782C28.3251 58.0965 28.9966 58.1557 29.6872 58.136C41.1211 57.8205 44.8812 40.4524 32.4114 36.9432C32.2579 36.9038 32.1045 36.8644 31.9893 36.825C23.8168 34.9324 18.4644 46.0117 25.6777 50.408C27.1549 51.2951 28.4403 51.4134 29.3803 50.3291C29.3803 50.3291 23.6442 46.6032 27.0974 42.2661C30.2819 38.2838 39.6823 39.4272 37.6295 48.8112C37.6103 48.9097 37.6103 49.0083 37.5912 49.1069C35.9413 57.7417 25.1789 58.8457 21.2653 51.4529C20.3829 49.7771 19.8265 47.8649 19.8265 45.6963C19.8265 38.1852 26.4835 33.9467 33.7927 33.4341C37.361 33.1778 40.9676 32.7441 43.3465 31.9556C54.3007 28.3873 51.7684 13.306 43.5383 11.3345C35.0588 9.28428 32.1236 14.3705 32.0661 14.1931C31.5865 12.5765 29.3227 6.52429 21.3996 4.27688C16.028 2.77861 9.25596 5.2823 5.64931 11.0388C2.50309 16.0659 1.96593 23.9319 8.14327 30.0433C13.6683 35.5238 19.9608 31.8767 22.5699 29.9053C22.8001 29.7279 22.6082 29.373 22.3396 29.4716C20.3253 30.2799 18.2918 30.9107 16.239 30.477C2.77167 27.5987 4.6901 7.56915 18.7138 7.51C18.7522 7.51 18.7906 7.51 18.8097 7.51C30.4354 9.54056 29.3995 19.1414 28.728 22.2956C28.6705 22.5519 28.9966 22.7293 29.1693 22.5322C31.433 20.0088 33.4474 17.8008 35.6344 16.9137C40.699 14.8437 43.5191 16.0265 45.073 18.2345C47.8164 22.1576 45.7061 27.7367 41.1403 28.9196C34.349 30.6741 27.4619 28.6633 20.9967 36.9038C15.8554 43.4489 17.1983 53.6411 24.8144 57.0911C25.6585 57.4854 26.6177 57.8205 27.7113 57.998"
            />
        </svg>
    );
};

const HeaderBG = () => {
    return (
        <svg
            viewBox="25 0 220 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                style={{ fill: 'currentColor' }}
                d="M0 0H270.309V66.1608L135.154 34.8004L0 66.1608V0Z"
            />
        </svg>
    );
}

const HeaderBGRegister = () => {
    return (
        <svg
            viewBox="5 0 250 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                style={{ fill: 'currentColor' }}
                d="M0.75 0H270.75V66.1608L135.75 84.5699L0.75 66.1608V0Z"
            />
        </svg>
    );
}

const LogoH = () => {
    return (
        <svg
            viewBox="0 0 223 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                style={{ fill: 'currentColor' }}
                d="M4.88113 20.2168C5.11503 21.2472 5.59841 22.0162 6.36247 22.5237C7.12653 23.0312 8.20244 23.2927 9.59022 23.2927C11.1807 23.2927 12.3346 23.0158 13.0519 22.4622C13.7536 21.9085 14.1122 21.0473 14.1122 19.8477C14.1122 18.8941 13.8159 18.1098 13.239 17.4946C12.6621 16.8794 11.7265 16.3873 10.4322 16.0028L6.40925 14.8186C4.55368 14.2649 3.13471 13.419 2.12116 12.281C1.10762 11.1429 0.593047 9.77411 0.593047 8.20541V7.88244C0.593047 5.49862 1.40388 3.65308 3.02556 2.33045C4.64723 1.02319 6.92382 0.361877 9.85531 0.361877C13.0363 0.361877 15.4064 1.16161 16.9501 2.76108C18.0105 3.89916 18.5406 5.29869 18.5406 6.91353C18.5406 7.29802 18.4627 7.55947 18.2911 7.68251C18.1196 7.82092 17.8078 7.88244 17.3244 7.88244H15.1569C14.6736 7.88244 14.3617 7.82092 14.2058 7.69789C14.0498 7.57485 13.9563 7.34416 13.9095 6.97505C13.6756 5.02186 12.1943 4.03757 9.46548 4.03757C6.7367 4.03757 5.28655 5.22179 5.28655 7.59023C5.28655 8.54376 5.56722 9.28197 6.12857 9.78949C6.68992 10.297 7.70347 10.7738 9.16921 11.2044L13.1922 12.3886C15.1102 12.9423 16.5447 13.8035 17.4959 14.9878C18.4471 16.172 18.9149 17.6023 18.9149 19.3094V19.7708C18.9149 22.0931 18.1196 23.8771 16.5291 25.1228C14.9386 26.3839 12.6776 26.9991 9.77734 26.9991C6.33128 26.9991 3.8208 26.2455 2.23031 24.7383C0.9205 23.4465 0.25 21.9547 0.25 20.2629C0.25 19.8784 0.327965 19.617 0.499488 19.4939C0.671012 19.3709 0.982872 19.294 1.46626 19.294H3.58691C4.03911 19.294 4.35097 19.3555 4.52249 19.4786C4.69401 19.6016 4.80316 19.8323 4.88113 20.2014"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M25.4951 1.45382V8.97438C26.7582 7.57485 28.5358 6.88277 30.8123 6.88277C32.9642 6.88277 34.5703 7.48257 35.615 8.69755C36.6597 9.91253 37.1899 11.5581 37.1899 13.6651V25.9533C37.1899 26.3686 37.1119 26.6608 36.956 26.8146C36.8001 26.9684 36.4726 27.0453 35.9736 27.0453H33.8842C33.4008 27.0453 33.0889 26.9684 32.9174 26.8146C32.7459 26.6608 32.6679 26.3839 32.6679 25.9533V14.3418C32.6679 13.1884 32.434 12.3117 31.9506 11.6965C31.4673 11.0814 30.594 10.7738 29.331 10.7738C28.1459 10.7738 27.2104 11.1429 26.5243 11.8811C25.8382 12.6193 25.4951 13.6497 25.4951 14.9724V25.9379C25.4951 26.3532 25.4172 26.6454 25.2612 26.7992C25.1053 26.953 24.7778 27.0299 24.2789 27.0299H22.1894C21.706 27.0299 21.3786 26.953 21.2226 26.7992C21.0511 26.6454 20.9731 26.3686 20.9731 25.9379V1.45382C20.9731 1.03857 21.0511 0.746364 21.2226 0.59257C21.3786 0.438775 21.706 0.361877 22.1894 0.361877H24.2789C24.7778 0.361877 25.1209 0.438775 25.2612 0.59257C25.4172 0.746364 25.4951 1.03857 25.4951 1.45382Z"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M47.7775 10.4662C46.4053 10.4662 45.4229 10.8199 44.7992 11.5274C44.1755 12.2348 43.8481 13.4037 43.7857 15.0339H51.4107C51.4107 13.4652 51.1144 12.3117 50.5375 11.5735C49.9449 10.8353 49.0249 10.4662 47.7619 10.4662M54.7788 18.402H43.7701V18.5866C43.7701 20.4629 44.0819 21.7855 44.7057 22.5698C45.3294 23.3542 46.3585 23.7541 47.7931 23.7541C49.6331 23.7541 50.7714 23.1081 51.1768 21.8009C51.3327 21.3702 51.6758 21.1703 52.2059 21.1703H54.5605C54.9815 21.1703 55.2933 21.2318 55.4649 21.3549C55.6364 21.4779 55.7299 21.7086 55.7299 22.0316C55.7299 22.5083 55.574 23.062 55.2621 23.6925C54.9503 24.3231 54.5761 24.8614 54.1395 25.292C53.4378 26.0148 52.5178 26.5685 51.3951 26.953C50.2724 27.3375 49.0405 27.5374 47.6995 27.5374C45.0175 27.5374 42.9437 26.7838 41.4779 25.292C40.0122 23.8002 39.2793 21.6625 39.2793 18.8941V15.972C39.2793 13.0807 40.0122 10.8353 41.4779 9.25122C42.9437 7.66713 45.0331 6.8674 47.7463 6.8674C50.4595 6.8674 52.5022 7.60562 53.9056 9.08204C55.3089 10.5585 56.0106 12.7116 56.0106 15.5568V17.3101C56.0106 17.7407 55.9327 18.0175 55.7767 18.1713C55.6208 18.3251 55.2933 18.3866 54.7944 18.3866"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M62.4038 0.946297C61.9361 0.561811 61.2967 0.361877 60.4859 0.361877C59.6751 0.361877 59.0825 0.561811 58.5991 0.946297C58.1314 1.33078 57.8975 1.88445 57.8975 2.60728V3.39163C57.8975 4.08371 58.1314 4.63737 58.5991 5.03724C59.0669 5.4371 59.6907 5.63704 60.4859 5.63704C61.2811 5.63704 61.9361 5.4371 62.4038 5.03724C62.8716 4.63737 63.1055 4.08371 63.1055 3.39163V2.60728C63.1055 1.88445 62.8716 1.33078 62.4038 0.946297Z"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M61.515 7.40567H59.4255C58.9421 7.40567 58.6302 7.48257 58.4587 7.62098C58.2872 7.7594 58.2092 8.05161 58.2092 8.49761V25.9379C58.2092 26.3839 58.2872 26.6761 58.4587 26.8146C58.6302 26.953 58.9421 27.0145 59.4255 27.0145H61.515C61.9983 27.0145 62.3102 26.953 62.4817 26.8146C62.6532 26.6761 62.7312 26.3839 62.7312 25.9379V8.48223C62.7312 8.03623 62.6532 7.74402 62.4817 7.6056C62.3102 7.46719 61.9983 7.39029 61.515 7.39029"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M70.2783 1.45382V25.9379C70.2783 26.3839 70.2004 26.6762 70.0288 26.8146C69.8573 26.953 69.5455 27.0145 69.0621 27.0145H66.9726C66.4892 27.0145 66.1618 26.953 66.0058 26.8146C65.8343 26.6762 65.7563 26.3839 65.7563 25.9379V1.45382C65.7563 1.00782 65.8343 0.715607 66.0058 0.577191C66.1618 0.438776 66.4892 0.377258 66.9726 0.377258H69.0621C69.5455 0.377258 69.8573 0.454156 70.0288 0.577191C70.2004 0.715607 70.2783 1.00782 70.2783 1.45382Z"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M80.3357 18.1405C79.1507 18.1405 78.2619 18.3712 77.6537 18.8172C77.0612 19.2632 76.7649 20.0322 76.7649 21.0934C76.7649 22.1546 77.0612 22.9082 77.6382 23.3388C78.2151 23.7694 79.0415 23.9693 80.1019 23.9693H80.7412C81.973 23.9693 82.8618 23.6771 83.392 23.0773C83.9221 22.4775 84.1872 21.4625 84.1872 20.0476V18.3558C83.3452 18.2636 82.6123 18.202 81.9886 18.1713C81.3493 18.1405 80.8035 18.1405 80.3202 18.1405M80.0863 14.7263C80.6632 14.7263 81.3337 14.7417 82.0822 14.7878C82.8306 14.8186 83.5323 14.8801 84.1872 14.957V13.9881C84.1872 12.5885 83.9221 11.6196 83.3764 11.0813C82.8306 10.5431 81.9886 10.2816 80.8503 10.2816C80.0707 10.2816 79.4158 10.4047 78.9168 10.6353C78.4178 10.866 78.0592 11.2813 77.8565 11.8503C77.7473 12.1579 77.6226 12.3425 77.4822 12.4347C77.3263 12.527 77.0456 12.5732 76.6558 12.5732H74.1921C73.7867 12.5732 73.506 12.5116 73.3657 12.3732C73.2097 12.2348 73.1318 12.0349 73.1318 11.758C73.1318 10.7891 73.5216 9.86637 74.3012 9.0205C74.9406 8.3438 75.767 7.8209 76.8117 7.45179C77.8565 7.0673 79.1975 6.88275 80.8815 6.88275C82.8774 6.88275 84.4367 7.1442 85.5438 7.6671C86.6509 8.19001 87.4462 8.95898 87.8984 9.97403C88.3506 10.9891 88.5845 12.2194 88.5845 13.6959V21.0011C88.5845 22.093 88.3662 23.1389 87.9451 24.1231C87.5085 25.1074 86.7757 25.9072 85.7309 26.4916C84.6862 27.0914 83.2049 27.399 81.3025 27.399H80.2422C77.5602 27.399 75.5799 26.9068 74.3012 25.9225C73.0226 24.9383 72.3833 23.3388 72.3833 21.1549C72.3833 19.5093 72.7264 18.2174 73.428 17.2947C74.1297 16.3719 75.0653 15.7106 76.2348 15.3261C77.4043 14.9416 78.7141 14.7417 80.133 14.7417"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M97.7688 2.62265C99.6244 2.62265 101.075 3.0379 102.104 3.86839C103.133 4.69888 103.725 5.89848 103.881 7.51332C103.928 7.97471 104.256 8.25154 104.77 8.25154H105.472C106.018 8.25154 106.361 7.91319 106.361 7.37491C106.361 6.23683 106.158 5.25254 105.737 4.42205C104.318 1.68451 101.605 0.284973 97.6909 0.284973C94.7282 0.284973 92.4048 1.17698 90.8299 2.91486C89.255 4.65274 88.4442 7.20574 88.4442 10.5123V17.6638C88.4442 20.9704 89.2083 23.5234 90.7208 25.2459C92.2489 26.9991 94.5255 27.8757 97.4726 27.8757C100.42 27.8757 102.556 27.0914 104.084 25.5534C105.597 24.0155 106.361 21.7547 106.361 18.8326V15.2184C106.361 14.6802 106.018 14.3418 105.472 14.3418H99.562C99.0163 14.3418 98.6732 14.6802 98.6732 15.2184V15.8029C98.6732 16.3411 99.0163 16.6795 99.562 16.6795H103.819V18.725C103.819 23.308 101.729 25.5381 97.4414 25.5381C95.2584 25.5381 93.6835 24.9075 92.6387 23.6003C91.594 22.293 91.0638 20.2014 91.0638 17.3869V10.743C91.0638 5.26792 93.2625 2.59189 97.7688 2.59189"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M119.225 8.00546H118.632C116.231 8.00546 114.391 8.86671 113.128 10.5738V8.88209C113.128 8.34381 112.785 8.00546 112.239 8.00546H111.506C110.96 8.00546 110.617 8.34381 110.617 8.88209V26.4301C110.617 26.9684 110.96 27.3067 111.506 27.3067H112.27C112.816 27.3067 113.159 26.9684 113.159 26.4301V17.4792C113.159 15.1723 113.627 13.4037 114.562 12.2194C115.482 11.0506 116.777 10.4816 118.523 10.4816H119.225C119.771 10.4816 120.114 10.1432 120.114 9.60493V8.88209C120.114 8.34381 119.771 8.00546 119.225 8.00546Z"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M123.513 6.72898H124.48C124.714 6.72898 125.275 6.72898 125.275 6.43677V0.654087C125.275 0.361877 124.714 0.361877 124.48 0.361877H123.513C123.279 0.361877 122.718 0.361877 122.718 0.654087V6.43677C122.718 6.72898 123.279 6.72898 123.513 6.72898Z"
            />
            <path
                style={{ fill: 'currentColor' }}
                sd="M124.371 8.00546H123.606C123.061 8.00546 122.718 8.34381 122.718 8.88209V26.4301C122.718 26.9684 123.061 27.3067 123.606 27.3067H124.371C124.916 27.3067 125.259 26.9684 125.259 26.4301V8.88209C125.259 8.34381 124.916 8.00546 124.371 8.00546Z"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M172.662 16.1412V19.2479C172.662 23.4926 170.931 25.5534 167.376 25.5534C163.821 25.5534 162.059 23.4926 162.059 19.2479V16.1412C162.059 11.8965 163.805 9.83562 167.376 9.83562C170.947 9.83562 172.662 11.8965 172.662 16.1412ZM167.532 7.49794C165.006 7.49794 163.166 8.17463 162.074 9.51265V1.74601C162.074 1.20773 161.731 0.869385 161.186 0.869385H160.422C159.876 0.869385 159.533 1.20773 159.533 1.74601V19.294C159.533 22.0162 160.203 24.1539 161.529 25.5996C162.87 27.076 164.819 27.8142 167.361 27.8142C169.902 27.8142 171.867 27.076 173.224 25.6303C174.58 24.2 175.266 22.1084 175.266 19.417V16.2796C175.266 10.4508 172.662 7.49794 167.548 7.49794"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M190.267 15.8951V16.2489H179.96C180.006 11.8657 181.737 9.74336 185.277 9.74336C188.816 9.74336 190.267 11.7581 190.267 15.9105M185.308 7.52872C182.766 7.52872 180.802 8.29769 179.445 9.80488C178.104 11.2967 177.418 13.4806 177.418 16.2796V19.4632C177.418 22.17 178.088 24.2616 179.398 25.6919C180.724 27.1222 182.657 27.8604 185.137 27.8604C187.616 27.8604 189.565 27.1837 190.844 25.8764C191.233 25.4919 191.576 25.0152 191.873 24.4769C192.185 23.8925 192.34 23.4311 192.34 23.062C192.34 22.6929 192.107 22.2622 191.452 22.2622H190.797C190.516 22.2622 190.142 22.3699 189.861 22.9236C189.44 23.8771 188.863 24.5845 188.146 24.9844C187.429 25.3997 186.446 25.615 185.23 25.615C181.675 25.615 179.944 23.5387 179.944 19.2786V18.525H191.842C192.387 18.525 192.73 18.1867 192.73 17.6484V15.8798C192.73 13.2037 192.091 11.1275 190.844 9.7126C189.581 8.28231 187.709 7.55948 185.308 7.55948"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M204.113 8.00546H203.521C201.119 8.00546 199.279 8.86671 198.016 10.5738V8.88209C198.016 8.34381 197.673 8.00546 197.128 8.00546H196.395C195.849 8.00546 195.506 8.34381 195.506 8.88209V26.4301C195.506 26.9684 195.849 27.3067 196.395 27.3067H197.159C197.704 27.3067 198.048 26.9684 198.048 26.4301V17.4792C198.048 15.1723 198.515 13.4037 199.451 12.2194C200.371 11.0506 201.665 10.4816 203.412 10.4816H204.113C204.659 10.4816 205.002 10.1432 205.002 9.60493V8.88209C205.002 8.34381 204.659 8.00546 204.113 8.00546Z"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M218.708 15.9259V18.9557C218.708 23.0927 216.962 25.1228 213.391 25.1228C209.82 25.1228 208.105 23.1081 208.105 18.9557V15.9259C208.105 11.7735 209.836 9.75874 213.391 9.75874C216.946 9.75874 218.708 11.7735 218.708 15.9259ZM219.254 9.65108C217.913 8.23617 215.964 7.51334 213.422 7.51334C210.881 7.51334 208.916 8.23617 207.559 9.65108C206.203 11.066 205.517 13.1268 205.517 15.7567V18.8172C205.517 24.5538 208.121 27.4605 213.235 27.4605C215.761 27.4605 217.601 26.7838 218.708 25.4458V26.6608C218.708 28.4448 218.287 29.7521 217.445 30.5364C216.603 31.3361 215.2 31.736 213.282 31.736C210.756 31.736 209.306 31.0439 208.822 29.6136C208.666 29.1676 208.339 28.9216 207.887 28.9216H207.232C206.686 28.9216 206.343 29.2599 206.343 29.7982C206.343 30.2442 206.483 30.721 206.78 31.2285C207.06 31.7052 207.419 32.1513 207.871 32.5357C208.417 32.9971 209.197 33.3662 210.148 33.6277C211.083 33.8738 212.128 33.9968 213.251 33.9968C218.568 33.9968 221.266 31.5207 221.266 26.6608V15.8029C221.266 13.1422 220.595 11.0814 219.27 9.66646"
            />
            <path
                style={{ fill: 'currentColor' }}
                d="M143.098 46.1619C143.628 46.2542 144.174 46.3003 144.735 46.285C154.028 46.0389 157.085 32.4896 146.949 29.752C146.824 29.7213 146.7 29.6905 146.606 29.6598C139.963 28.1833 135.613 36.8266 141.476 40.2562C142.677 40.9483 143.721 41.0406 144.485 40.1947C144.485 40.1947 139.823 37.288 142.63 33.9045C145.218 30.7978 152.859 31.6898 151.19 39.0105C151.175 39.0874 151.175 39.1643 151.159 39.2412C149.818 45.9774 141.071 46.8386 137.89 41.0713C137.172 39.7641 136.72 38.2723 136.72 36.5805C136.72 30.7209 142.131 27.4144 148.072 27.0145C150.972 26.8146 153.904 26.4762 155.837 25.861C164.741 23.0773 162.683 11.312 155.993 9.7741C149.101 8.17463 146.715 12.1425 146.669 12.0041C146.279 10.743 144.439 6.02151 137.999 4.26825C133.648 3.08403 128.144 5.03722 125.212 9.52803C122.655 13.4498 122.219 19.5862 127.24 24.3538C131.73 28.6293 136.845 25.7841 138.966 24.2462C139.153 24.1078 138.997 23.8309 138.778 23.9078C137.141 24.5384 135.488 25.0305 133.82 24.6922C122.874 22.4468 124.433 6.82124 135.816 6.7751C135.847 6.7751 135.878 6.7751 135.894 6.7751C145.343 8.35919 144.501 15.849 143.955 18.3097C143.909 18.5096 144.174 18.6481 144.314 18.4943C146.154 16.5257 147.791 14.8032 149.569 14.1111C153.685 12.4963 155.978 13.419 157.241 15.1415C159.47 18.202 157.755 22.5544 154.044 23.4772C148.524 24.846 142.926 23.2773 137.671 29.7059C133.492 34.8119 134.584 42.7631 140.774 45.4545C141.46 45.7621 142.24 46.0235 143.129 46.1619"
            />
        </svg>
    );
}

export { SheilaLogo, HeaderBG, HeaderBGRegister, LogoH };