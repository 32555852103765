import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBBtn, MDBTypography, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { PiSealQuestion } from "react-icons/pi";
import LoadingScreen from '../components/loading';
import logo from "../imgs/logo.png"

function Index() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (document.readyState === 'complete') {
            setLoading(false);
        } else {
            const handleLoad = () => {
                setLoading(false);
            };

            window.addEventListener('load', handleLoad);

            return () => window.removeEventListener('load', handleLoad);
        }
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center">

                <img className='main-page-logo' src={logo} />

                <MDBRow className='mb-2 main-page-title-wrapper'>
                    <MDBCol>
                        <MDBTypography tag='h1' className='text-center pt-2 main-page-title'>Bem vindo!</MDBTypography>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='buttons-wrapper ms-3 me-3 mb-5 mt-5'>
                    <div>
                        <h3 className='buttons-title'>Descubra seu perfil como terapeuta e desenvolva o seu potencial.</h3>
                        <h3 className='buttons-subtitle'>Faça esse teste exclusivo e desvende quais seus pontos fortes, seus desafios e ações práticas para impulsionar sua jornada como terapeuta.</h3>
                    </div>
                    <MDBCol className='d-flex flex-column ms-4 me-4 mt-5'>
                        <a href='/questionario' className='d-flex justify-content-center'>
                            <MDBBtn tag='a' color='warning' className='buttons-main'>
                                <PiSealQuestion /> Faça o teste
                            </MDBBtn>
                        </a>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    );
}

export default Index