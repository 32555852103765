import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBBtn, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaWhatsapp } from "react-icons/fa";
import LoadingScreen from '../components/loading';
import { HeaderBGRegister } from '../components/svgs';
import { LogoH } from '../components/svgs';

function End() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (document.readyState === 'complete') {
            setLoading(false);
        } else {
            const handleLoad = () => {
                setLoading(false);
            };

            window.addEventListener('load', handleLoad);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('load', handleLoad);
        }
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center justify-content-between" style={{}}>
                <MDBRow className='mb-4 qa-page-title-wrapper'>
                    <div className='qa-header-bg'>
                        <HeaderBGRegister />
                    </div>
                    <div className='qa-register-logo'>
                        <LogoH />
                    </div>
                </MDBRow>
                <MDBContainer className='mb-4 qa-container'>
                    <MDBRow className='m-0'>
                        <MDBCol>
                            <h1 className='qa-page-title'>Olá querido(a)!</h1>
                            <p className='qa-page-subtitle'>Que bom que você se interessou por esse teste. Fico profundamente grata e feliz em saber que existem profissionais procurando se conhecer melhor para não pararem a sua jornada de desenvolvimento, tanto para oferecer mais aos seus pacientes, quanto para sua evolução pessoal.</p>
                            <p className='qa-page-subtitle'>Veja o resultado do seu teste e um vídeo que eu fiz aprofundando um pouco mais sobre os caminhos de crescimento para cada perfil. </p>
                            <p className='qa-page-subtitle'>Vamos falar mais sobre isso? Não deixe de assistir ao vídeo!</p>
                        </MDBCol>
                        <MDBCol md={12} className='mt-4 mb-4 d-flex justify-content-center'>
                            <div className='qa-video-wrapper'>
                                <iframe
                                    width="100%"
                                    height="auto"
                                    src="https://www.youtube.com/watch?v=QfCNfKLMikg"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </MDBCol>
                        <MDBCol md={12} className='d-flex justify-content-center flex-column'>
                            <p className='qa-page-subtitle'>Agora estou te esperando no WhatsApp, na nossa comunidade exclusiva para terapeutas, onde você vai encontrar conteúdo sobre carreira, desenvolvimento pessoal e atualizações, além de fazer contatos e estar por dentro das novidades.</p>
                            <MDBCol md={12} className='mb-8 mt-4 d-flex justify-content-center' >
                                <a className='qa-button-r'>Quero fazer parte da comunidade.</a>
                            </MDBCol>
                            <p className='qa-page-subtitle'>UM BEIJÃO E TE VEJO LÁ!</p>
                            <p className='pt-0 qa-page-subtitle'>Sheila Grínberg</p>
                        </MDBCol>
                        <MDBCol md={12}></MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBContainer>
        </>
    );
}

export default End