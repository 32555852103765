import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBBtn, MDBTypography, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import LoadingScreen from '../components/loading';
import { SheilaLogo } from '../components/svgs';
import { HeaderBG } from '../components/svgs';
import { HeaderBGRegister } from '../components/svgs';
import { LogoH } from '../components/svgs';

const questions = [
    {
        question: '1- Quando o paciente compartilha um drama, você:',
        options: [
            { letter: 'A', text: 'Sente que vai carregar seus problemas nas costas pra sempre.' },
            { letter: 'B', text: 'Pensa "e agora, o que eu faço?"' },
            { letter: 'C', text: 'Fica com peso na consciência se cobrar.' },
            { letter: 'D', text: 'Quer ajudar tanto que se perde no meio do caminho.' },
            { letter: 'E', text: 'Estica o ouvido com carinho e fé, sem passar do limite.' }
        ],
        color: '#163970'
    },
    {
        question: '2- Depois da sessão, é provável que você:',
        options: [
            { letter: 'A', text: 'Fica remoendo o bolo até em casa.' },
            { letter: 'B', text: 'Se pergunta se realmente ajudou o paciente.' },
            { letter: 'C', text: 'Acha que devia ter feito a consulta de graça.' },
            { letter: 'D', text: 'Reflete e estuda mais, contudo sem coragem de colocar em prática.' },
            { letter: 'E', text: 'Faz sua faxina energética e pula pro próximo paciente.' }
        ],
        color: '#652878'
    },
    {
        question: '3- Seu maior desafio é:',
        options: [
            { letter: 'A', text: 'Proteger-se de absorver a bad do paciente.' },
            { letter: 'B', text: 'Criar confiança no que faz.' },
            { letter: 'C', text: 'Colocar um valor justo nos seus conhecimentos.' },
            { letter: 'D', text: 'Sair da teoria para a prática.' },
            { letter: 'E', text: 'Chegar em mais gente que precisa.' }
        ],
        color: '#BF75A2'
    },
    {
        question: '4- Como se vê no momento:',
        options: [
            { letter: 'A', text: 'Sobrecarregado com a responsabilidade.' },
            { letter: 'B', text: 'Sempre estudando, nunca vivendo na pele.' },
            { letter: 'C', text: 'Mais amigo que profissional.' },
            { letter: 'D', text: 'Motivado e querendo evoluir sempre.' },
            { letter: 'E', text: 'Paralisado nas dúvidas.' }
        ],
        color: '#BA3430'
    },
    {
        question: '5- Pra você evoluir é:',
        options: [
            { letter: 'A', text: 'Encontrar mecanismos de proteção.' },
            { letter: 'B', text: 'Fazer cursos até sentir que a insegurança passou.' },
            { letter: 'C', text: 'Esperar o tempo resolver.' },
            { letter: 'D', text: 'Estudar mas sem colocar em prática por enquanto.' },
            { letter: 'E', text: 'Investir em mentorias, supervisão e prática reflexiva.' }
        ],
        color: '#E0701B'
    },
    {
        question: '6- Quando ouvir crítica ou feedback:',
        options: [
            { letter: 'A', text: 'Entra em parafuso.' },
            { letter: 'B', text: 'Questiona se escolheu a carreira certa.' },
            { letter: 'C', text: 'Se desculpa e oferece desconto.' },
            { letter: 'D', text: 'Usa como combustível para estudar mais.' },
            { letter: 'E', text: 'Enxerga como chance de se melhorar.' }
        ],
        color: '#E0A824'
    },
    {
        question: '7- O que te motiva é:',
        options: [
            { letter: 'A', text: 'Vontade de se proteger melhor.' },
            { letter: 'B', text: 'Momentos raros de confiança, onde você encontra sentido.' },
            { letter: 'C', text: 'Gratidão dos pacientes.' },
            { letter: 'D', text: 'Saber que sempre tem o que aprender.' },
            { letter: 'E', text: 'Poder ajudar em transformações reais.' }
        ],
        color: '#728037'
    },
];

const messagesMap = {
    A: 'Inspirado a Proteger: Ei, você tem um superpoder: a sensibilidade! Não é um fardo, é um dom! Ao aprender técnicas de proteção e limpeza energética, você preserva sua energia e se torna um farol brilhante para os outros. Imagine-se com uma aura luminosa, filtrando o que não presta e aumentando sua habilidade de curar. Essa jornada é uma dança com a energia ao seu redor, sem perder seu próprio ritmo.',
    B: 'Construindo Pontes de Confiança: Cada passo que você dá é um tijolinho a mais na construção da sua confiança. Celebre as pequenas vitórias e reconheça o progresso, pois eles compõem a estrada para a autoconfiança. Permita-se ser aprendiz, sabendo que cada experiência é uma chance de crescer. Se jogue em práticas supervisionadas e mentorias, encarando-as como uma aventura onde cada desafio é um convite para descobrir a força que já existe em você.',
    C: 'Valorizando Seu Dom: Sua generosidade é um tesouro, mas lembre-se de guardar seus tesouros num cofre. Aprenda a valorizar seu trabalho, desenhando um mapa que leva ao reconhecimento do seu valor, tanto por você quanto pelos outros. Workshops sobre auto valorização e estratégias de precificação não são apenas aulas, são portais para um mundo onde sua generosidade e profissionalismo caminham juntinhos, em perfeita harmonia.',
    D: 'Da teoria à ação: você já tem o conhecimento, agora é hora de colocá-lo em prática. Cada conceito aprendido é uma semente esperando para ser plantada no solo fértil da experiência. Dê o primeiro passo, mesmo que seja pequeno, pois é na ação que o conhecimento floresce e se transforma em sabedoria. Procure oportunidades para aplicar o que aprendeu, enxergando cada sessão como um laboratório vivo onde teorias se tornam transformações palpáveis.',
    E: 'Expandindo horizontes: sua jornada até aqui é uma tapeçaria rica e vibrante, tecida com dedicação, aprendizado e amor. Agora é hora de acrescentar novas cores e padrões nessa obra de arte. Continue buscando novos aprendizados, desafios e maneiras de expandir seu impacto. Mentorar outros terapeutas, explorar novas modalidades de cura ou até escrever sobre suas experiências são formas incríveis de crescer e inspirar crescimento ao seu redor. Lembre-se, um verdadeiro mestre eleva os outros enquanto continua sua própria ascensão.',
};

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function Questionary() {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState({});
    const [loading, setLoading] = useState(true);
    const [shuffledOptions, setShuffledOptions] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        telephone: '',
    });
    const [formValid, setFormValid] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [questionsCompleted, setQuestionsCompleted] = useState(false);
    const [finalMessage, setFinalMessage] = useState('');
    const [finalAnswer, setFinalAnswer] = useState('');
    const [finalScreen, setFinalScreen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setShuffledOptions(shuffleArray(questions[currentQuestion].options));
        document.documentElement.style.setProperty('--main-color', questions[currentQuestion].color);
        if (document.readyState === 'complete') {
            setLoading(false);
        } else {
            const handleLoad = () => {
                setLoading(false);
            };

            window.addEventListener('load', handleLoad);

            return () => window.removeEventListener('load', handleLoad);
        }
    }, [currentQuestion]);

    const handleAnswerChange = (e) => {
        const { name, value } = e.target;
        const originalLetter = questions[currentQuestion].options.find(option => option.text === value).letter;
        const existingAnswers = answers[name] || [];
        const updatedAnswers = [...existingAnswers, originalLetter];
        setAnswers({ ...answers, [name]: updatedAnswers });
    };

    const handleRegistrationChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            const lettersCount = {};
            Object.values(answers).forEach((selectedLetter) => {
                lettersCount[selectedLetter] = (lettersCount[selectedLetter] || 0) + 1;
            });

            const sortedOptions = Object.keys(lettersCount).sort((a, b) => lettersCount[b] - lettersCount[a]);
            const firstMostSelectedOption = sortedOptions[0];
            const secondMostSelectedOption = sortedOptions[1];

            const answersMap = {
                A: 'O Terapeuta Sensível',
                B: 'O Terapeuta Inseguro',
                C: 'O Amigo-Terapeuta',
                D: 'O Terapeuta Estudante',
                E: 'O Terapeuta Abundante',
            };

            const firstAnswer = answersMap[firstMostSelectedOption];
            const secondAnswer = answersMap[secondMostSelectedOption];

            if (formData.name && formData.email && formData.telephone) {
                const data = {
                    nome: formData.name,
                    email: formData.email,
                    numero: formData.telephone,
                    resultado1: firstAnswer,
                    resultado2: secondAnswer
                };

                const response = await fetch('https://quizz.sheilagrinberg.com/api/contato/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

                const result = await response.json();

                if (result.success) {
                    setFormSubmitted(true);
                    setFinalMessage(messagesMap[firstMostSelectedOption]);
                    setFinalAnswer(answersMap[firstMostSelectedOption]);
                    document.documentElement.style.setProperty('--main-color', '#179296');
                } else {
                    alert("Erro ao enviar os dados. Tente novamente.");
                }
            } else {
                alert("Por favor, preencha todos os campos!");
            }
        } catch (error) {
            console.error('Erro:', error);
            alert("Ocorreu um erro ao enviar os dados. Tente novamente.");
        }
    };

    const renderOptions = () => {
        return shuffledOptions.map((option, index) => (
            <MDBCol md={12} key={index} className='qa-option-wrapper'>
                <div className='custom-radio'>
                    <input
                        type="radio"
                        id={`option-${index}`}
                        name={`question-${currentQuestion}`}
                        value={option.text}
                        checked={answers[`question-${currentQuestion}`] && answers[`question-${currentQuestion}`].includes(option.letter)}
                        onChange={handleAnswerChange}
                    />
                    <label htmlFor={`option-${index}`} className='qa-option-label' style={{ color: currentQuestion === 0 ? '#162070' : '#000' }}>
                        <div className='circle' data-letter={String.fromCharCode(65 + index)}></div>
                        {option.text}
                    </label>
                </div>
            </MDBCol>
        ));
    };

    const handleNext = () => {
        if (answers[`question-${currentQuestion}`] && answers[`question-${currentQuestion}`].length > 0) {
            setCurrentQuestion(currentQuestion + 1);
            setShowMessage(false);
            if (currentQuestion + 1 === questions.length) {
                setQuestionsCompleted(true);
            }
        } else {
            setShowMessage(true);
        }
    };

    const Message = ({ show, onClose }) => {
        if (!show) return null;

        return (
            <div className="message">
                <p>Por favor, selecione alguma das opções antes de seguir.</p>
            </div>
        );
    };

    const handlePrevious = () => {
        setCurrentQuestion(currentQuestion - 1);
    };

    const renderTimelineBalls = () => {
        const balls = [];
        for (let i = 0; i <= 6; i++) {
            balls.push(
                <div
                    key={i}
                    className={`mb-4 timeline-ball ${i < currentQuestion ? 'previous' : ''} ${i <= currentQuestion ? 'filled' : ''}`}
                    onClick={() => setCurrentQuestion(i)}
                ></div>
            );
        }
        return balls;
    };

    if (loading) {
        return <LoadingScreen />;
    }

    if (questionsCompleted && !formSubmitted) {
        return (
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center justify-content-between" style={{ minHeight: '90vh' }}>
                <MDBRow className='mb-4 qa-page-title-wrapper'>
                    <div className='qa-header-bg'>
                        <HeaderBGRegister />
                    </div>
                    <div className='qa-register-logo'>
                        <LogoH />
                    </div>
                </MDBRow>
                <MDBContainer className='mb-4 qa-container'>
                    <MDBRow className='mb-4 ms-4 me-4'>
                        <MDBCol>
                            <h1 className='qa-page-title'>Para receber sua resposta, só temos mais uma etapa:</h1>
                            <p className='qa-page-subtitle'>Realize o cadastro abaixo para descobrir seu perfil de terapeuta holístico</p>
                        </MDBCol>
                        <MDBCol md={12} className='mb-4 d-flex justify-content-center flex-column qa-register-wrapper'>
                            <label className='pe-4'>Nome: </label>
                            <input
                                className='qa-input-register'
                                type='text'
                                name='name'
                                label='Nome'
                                value={formData.name}
                                onChange={handleRegistrationChange}
                            />
                        </MDBCol>
                        <MDBCol md={12} className='mb-4 d-flex justify-content-center flex-column qa-register-wrapper'>
                            <label className='pe-4'>E-mail: </label>
                            <input
                                className='qa-input-register'
                                type='email'
                                name='email'
                                label='E-mail'
                                value={formData.email}
                                onChange={handleRegistrationChange}
                            />
                        </MDBCol>
                        <MDBCol md={12} className='mb-4 d-flex justify-content-center flex-column qa-register-wrapper'>
                            <label className='pe-4'>Telefone: </label>
                            <input
                                className='qa-input-register'
                                type='tel'
                                name='telephone'
                                label='Telefone'
                                value={formData.telephone}
                                onChange={handleRegistrationChange}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <a className='qa-button-r' onClick={() => { handleSubmit(); setFinalScreen(true); }}>Enviar</a>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBContainer>
        );
    }

    if (formSubmitted || finalScreen) {
        return (
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center justify-content-between" style={{ minHeight: '90vh' }}>
                <MDBRow className='mb-4 qa-page-title-wrapper'>
                    <div className='qa-header-bg'>
                        <HeaderBGRegister />
                    </div>
                    <div className='qa-register-logo'>
                        <LogoH />
                    </div>
                </MDBRow>
                <MDBContainer className='mb-4 qa-container'>
                    <MDBRow className='m-0'>
                        <MDBCol>
                            <h1 className='qa-page-title'>Olá querido(a)!</h1>
                            <p className='qa-page-subtitle'>Que bom que você se interessou por esse teste. Fico profundamente grata e feliz em saber que existem profissionais procurando se conhecer melhor para não pararem a sua jornada de desenvolvimento, tanto para oferecer mais aos seus pacientes, quanto para sua evolução pessoal.</p>
                            <p className='qa-page-subtitle'>Veja o resultado do seu teste e um vídeo que eu fiz aprofundando um pouco mais sobre os caminhos de crescimento para cada perfil.</p>
                            <p className='qa-page-answer-title'>{finalAnswer}</p>
                            <p className='qa-page-answer-message'>{finalMessage}</p>
                            <p className='qa-page-subtitle'>Vamos falar mais sobre isso? Não deixe de assistir ao vídeo!</p>
                        </MDBCol>
                        <MDBCol md={12} className='mt-4 mb-4 d-flex justify-content-center'>
                            <div className='qa-video-wrapper'>
                                <iframe
                                    width="100%"
                                    height="400px"
                                    src="https://www.youtube.com/embed/QfCNfKLMikg"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </MDBCol>
                        <MDBCol md={12} className='d-flex justify-content-center flex-column'>
                            <p className='qa-page-subtitle'>Agora estou te esperando no WhatsApp, na nossa comunidade exclusiva para terapeutas, onde você vai encontrar conteúdo sobre carreira, desenvolvimento pessoal e atualizações, além de fazer contatos e estar por dentro das novidades.</p>
                            <MDBCol md={12} className='mb-8 mt-4 d-flex justify-content-center'>
                                <a href='https://chat.whatsapp.com/G6r1xOnj6Qh0kPzM3KOGmp' className='qa-button-r'>Quero fazer parte da comunidade.</a>
                            </MDBCol>
                            <p className='qa-page-subtitle'>UM BEIJÃO E TE VEJO LÁ!</p>
                            <p className='pt-0 qa-page-subtitle'>Sheila Grínberg</p>
                        </MDBCol>
                        <MDBCol md={12}></MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBContainer>
        );
    }

    return (
        <>
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center justify-content-between" style={{ minHeight: '90vh' }}>
                <MDBRow className='mb-4 qa-page-title-wrapper'>
                    <div className='qa-header-bg'>
                        <HeaderBG />
                    </div>
                    <div className='qa-header-logo'>
                        <SheilaLogo />
                    </div>
                </MDBRow>
                <MDBContainer className='mb-4 qa-container'>
                    <MDBRow style={{ width: '100%' }} className='qa-questions-wrapper'>
                        <MDBCol>
                            <MDBTypography tag='h1' className='mt-2 mb-4 qa-page-title'>{questions[currentQuestion].question}</MDBTypography>
                        </MDBCol>
                        {renderOptions()}
                    </MDBRow>
                </MDBContainer>
                <Message show={showMessage} onClose={() => setShowMessage(false)} />
                <MDBRow className='mb-4 qa-buttons-wrapper'>
                    <MDBCol sd={4} className=''>
                        {currentQuestion > 0 && <a className='qa-button-l' onClick={handlePrevious}>Voltar</a>}
                    </MDBCol>
                    <MDBCol sd={8} className=''>
                        {currentQuestion < questions.length - 1 && <a className='qa-button-r' onClick={handleNext}>Continuar</a>}
                        {currentQuestion === questions.length - 1 && <a className='qa-button-r' onClick={() => setQuestionsCompleted(true)}>Continuar</a>}</MDBCol>
                </MDBRow>
                <div className="timeline">
                    {renderTimelineBalls()}
                </div>
            </MDBContainer>
        </>
    );
}

export default Questionary;
