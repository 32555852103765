import React from 'react';
import { MDBContainer, MDBSpinner } from 'mdb-react-ui-kit';

function LoadingScreen() {
  return (
    <MDBContainer fluid className="p-0 d-flex flex-column align-items-center vh-100 justify-content-center">
      <MDBSpinner grow className="text-warning" />
      <h4 className="mt-3 text-warning">Carregando...</h4>
    </MDBContainer>
  );
}

export default LoadingScreen;