import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Index from './screen'
import Questionary from './screen/questionary'
import End from './screen/end'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Index />} />
        <Route path="/questionario" index element={<Questionary />} />
        <Route path="/final" index element={<End />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
